const initialEducationFormState = {
    data: {
        birthdate: '',
        level: '',
        year: '',   
        name: '',
        country: '',
        countryCode: '',
        state: '',
        stateCode: '',
        city: ''
    },
    touched: {
        birthdateTouched: false,
        levelTouched: false,
        yearTouched: false, 
        nameTouched: false,
        countryTouched: false,
        cityTouched: false,
        stateTouched: false
    },
    errors: {
        birthdate: '',
        level: '',
        year: '',
        name: '',
        country: '',
        city: '',
        state: ''
    },
    exception: {
        code: null,
        error: false,
        errorMessage: ''
    },
    levels: [
        {
            name: 'No high school diploma/GED',
            value: 'none'
        },
        {
            name: 'Completed high school diploma/GED',
            value: 'highschool'
        },
        {
            name: 'Associates degree',
            value: 'associates'
        },
        {
            name: 'Bachelors degree',
            value: 'bachelors'
        },
        {
            name: 'Masters degree',
            value: 'masters'
        },
        {
            name: 'PhD',
            value: 'phd'
        }
    ]
};

export default initialEducationFormState;