import axios from 'axios';

const INITIALIZE_STATES = '@@states/GET_STATES';

export const initializeStatesStore = (countryCode) => async (dispatch) => {
    const { data } = await axios.get(`${process.env.REACT_APP_USER}/api/${ countryCode ? countryCode : 'US' }/states`);
    dispatch({type: INITIALIZE_STATES, data});
};

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case INITIALIZE_STATES:
			return action.data;
		default:
			return state;
		case 'UPDATE_STATES':
			return action.data
	};
};