import React, { Component } from 'react'; 
import axios from 'axios';
import queryString from 'query-string';
import {connect} from 'react-redux'
import { updateCountry, updateState } from '../../redux/helper.redux';
import { Select, Alert } from '../../components';
import { initializeCountriesStore } from '../../redux/countries.redux';
import { initializeStatesStore } from '../../redux/states.redux';

class OrganizationForm extends Component {

    constructor() {
        super();
        this.state = {
            cartId: this.getQueryParam().cartId
        };
    };

    /**
	 * @method
	 * @description React lifecycle hook that will not perform logic until after the component fully mounts.
	 * @return {Void}
	 */
	componentDidMount = async () => {
        // get/set in redux countries
        this.props.initializeCountries();  

        // get/set in redux states
        this.props.initializeStates(this.props.organizationForm.user.countryCode);
    };

    /**
     * @method
     * @description Get query parameters.
     * @return {String}
     */
    getQueryParam = () => {
        const {brandCode, cartId} = queryString.parse(window.location.search);
		return {brandCode, cartId};
    };

    /**
     * Is form valid
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.props.organizationForm;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(touched => !touched && (valid = false));
        return valid;
    };


    /**
     * @method
     */
    handleSubmit = e => {
        e.preventDefault();
        if (this.isFormValid()) {
            axios.post(`${process.env.REACT_APP_API}/user-service/register`, {user: this.props.userForm.user, organization: this.props.organizationForm.user, cartId: this.props.userForm.cartId}).then((response) => {
				axios.post(`${process.env.REACT_APP_API}/auth-service/oauth/token`, queryString.stringify({grant_type: 'password', username: this.props.userForm.user.email, password: this.props.userForm.user.password, brandCode: this.getQueryParam().brandCode}), {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': "Basic d2ViYXBwOndlYmFwcC1zZWNyZXQ="
					},
				}).then((response) => {
					const {access_token} = response.data;
					localStorage.setItem('auth', access_token);
				});
                window.top.location.href = response.data.redirectUrl;
            })
            .catch(e => {
                const { message, status } = e.response.data;
                this.props.updateError({error: true, errorMessage: message, code: status})
            });
        }
        else {
            console.log('form is not valid', this.props.organizationForm);
        }  
    };

    
    render() {
        return (
            <form onSubmit={e => this.handleSubmit(e)}>

                <Alert active={this.props.organizationForm.exception.error} description={this.props.organizationForm.exception.errorMessage} code={this.props.organizationForm.exception.code} />

                <div className="split-form">

                        <div className={`form-group ${this.props.organizationForm.errors.name && 'error'} ${this.props.organizationForm.touched.nameTouched && !this.props.organizationForm.errors.name.length && 'success'}`}>
                            <label>Organization Name <small>required</small></label>
                            <input type="text" className="form-control" name="name" tabIndex="1" value={this.props.organizationForm.user.name} onChange={e => this.props.onChangeName(e.target.value)} />
                            <p className="text-error">{this.props.organizationForm.errors.name }</p>
                        </div>

                        <div className="form-group">
                            <label>Phone Number</label>
                            <input type="text" className="form-control" name="phone" tabIndex="2" value={this.props.organizationForm.user.phone} onChange={e => this.props.onChangePhone(e.target.value)} />
                        </div> 

                        <div className={`form-group ${this.props.organizationForm.errors.address1 && 'error'} ${this.props.organizationForm.touched.address1Touched && !this.props.organizationForm.errors.address1.length && 'success'}`}>
                            <label>Address Line 1 <small>required</small></label>
                            <input type="text" className="form-control" name="address1" tabIndex="3" value={this.props.organizationForm.user.address1} onChange={e => this.props.onChangeAddress1(e.target.value)} />
                            <p className="text-error">{this.props.organizationForm.errors.address1 }</p>
                        </div>

                        <div className="form-group">
                            <label>Address Line 2</label>
                            <input type="text" className="form-control" name="address2" tabIndex="4" value={this.props.organizationForm.user.address2} onChange={e => this.props.onChangeAddress2(e.target.value)} />
                        </div>

                        <div className={`form-group ${this.props.organizationForm.errors.city && 'error'} ${this.props.organizationForm.touched.cityTouched && !this.props.organizationForm.errors.city.length && 'success'}`}>
                            <label>Suburb/City <small>required</small></label>
                            <input type="text" className="form-control" name="city" tabIndex="5" value={this.props.organizationForm.user.city} onChange={e => this.props.onChangeCity(e.target.value)} />
                            <p className="text-error">{this.props.organizationForm.errors.city }</p>
                        </div>

                        <div className={`form-group ${this.props.organizationForm.errors.state && 'error'} ${this.props.organizationForm.touched.stateTouched && !this.props.organizationForm.errors.state.length && 'success'}`}>
                            <label>State Province <small>required</small></label>
                            {
                                this.props.states
                                ? <Select name="state" options={this.props.states} tabIndex={6} value={this.props.organizationForm.user.stateCode || ''} change={e => this.props.onChangeState(e.target.value, this.props.organizationForm.user.countryCode)} />    
                                : <input type="text" className="form-control" name="state" tabIndex="8" value={this.props.organizationForm.user.state} onChange={e => this.props.onChangeState(e.target.value, this.props.organizationForm.user.countryCode)} />
                            }
                            <p className="text-error">{this.props.organizationForm.errors.state }</p>
                        </div>

                        <div className={`form-group ${this.props.organizationForm.errors.zip && 'error'} ${this.props.organizationForm.touched.zipTouched && !this.props.organizationForm.errors.zip.length && 'success'}`}>
                            <label>Zip/Postcode <small>required</small></label>
                            <input type="text" className="form-control" name="zip" tabIndex="7" value={this.props.organizationForm.user.zip} onChange={e => this.props.onChangeZip(e.target.value)} />
                            <p className="text-error">{this.props.organizationForm.errors.zip }</p>
                        </div>

                        <div className={`form-group ${this.props.organizationForm.errors.country && 'error'} ${this.props.organizationForm.touched.countryTouched && !this.props.organizationForm.errors.country.length && 'success'}`}>
                            <label>Country <small>required</small></label>
                            <Select name="country" options={this.props.countries} tabIndex={8} value={this.props.organizationForm.user.countryCode} change={e => this.props.onChangeCountry(e)} />
                            <p className="text-error">{this.props.organizationForm.errors.country }</p>
                        </div>

                    <p>{this.props.continue}</p>
                    <button type="button" className="btn primary pull-left" onClick={() => this.props.history.push(`/register?brandCode=${this.getQueryParam().brandCode}&group=true`)}>Back</button>
                    <button type="submit" className={`btn primary pull-right ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Create Account</button>
                </div>
            </form>
        );
    };
};


const mapStateToProps = state => ({
    userForm: state.userForm,
    organizationForm: state.organizationForm,
    countries: state.countries,
    states: state.states
});


const mapDispatchToProps = dispatch => {
	return {
        initializeCountries: () => dispatch(initializeCountriesStore()),
        initializeStates: countryCode => dispatch(initializeStatesStore(countryCode)),
        updateError: value => dispatch({ type: 'UPDATE_ORGANIZATION_ERROR', value }),
        onChangeName: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_NAME', value }),
        onChangePhone: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_PHONE', value }),
        onChangeAddress1: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ADDRESS_1', value }),
        onChangeAddress2: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ADDRESS_2', value }),
        onChangeCity: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_CITY', value }),
        onChangeCountry: event => dispatch(updateCountry('UPDATE_FIELD_ORGANIZATION_COUNTRY', event.target.value)),
        onChangeState: (value, countryCode) => dispatch(updateState('UPDATE_FIELD_ORGANIZATION_STATE', value, countryCode)),
        onChangeZip: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ZIP', value })
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);