import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { Alert, Spinner } from '../components';

/**
 * Recover Component
 */

class Recover extends Component {

    constructor() {
        super();
        this.state = {
            token: this.getQueryParameter().token,
            password: '',            
            passwordConfirm: '',            
            touched: {
                passwordTouched: false,
                passwordConfirmTouched: false,
            },
            errors: {
                password: '',
                passwordConfirm: ''
            },
            exception: {
                load: true,
                code: null,
                error: false,
                errorMessage: '',
            },
            fetching: true
        };
    };


    async componentDidMount() {
        const { token } = this.state;
        await axios.get(`${process.env.REACT_APP_API}/auth-service/login/resetPasswordRequest/${token}`)
        .then((response) => {
            this.setState({token: response.data.token});
        })
        .catch(e => {
            const { message, status } = e.response.data;
            this.setState({exception: {error: true, errorMessage: message, code: status}});
        });
        this.setState({fetching: false});
    };


    /**
     * @method
     * @description Get token url query parameter.
     * @return {String}
     */
    getQueryParameter = () => {
        const {token} = queryString.parse(window.location.search);
		return {token};
    };


     /**
     * Handle change
     * @method
     * @param {InputEvent} event Event from input field element.
     * @description On every key press of any of the input fields, confirm value is valid. If not valid, assign an error message to the errors state.
     */
    handleChange = event => {        
        const { name, value } = event.target; // destruct event target
        let errors = this.state.errors;
        switch (name) {
            case 'password':
                const capitalLetter = RegExp(/[A-Z]/);
                const lowercaseLetter = RegExp(/[a-z]/);
                const specialChar = RegExp(/[\d!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?']+/);
                if (value.length < 1) {
                    errors.password = 'X You must enter a password.';                
                }
                else {
                    if ( value.length >= 8 && capitalLetter.test(value) && lowercaseLetter.test(value) && specialChar.test(value) ) {
                        errors.password = '';                    
                    }
                    else {
                        errors.password = 'X Password must be at least 8 characters long and include an uppercase letter, a lowercase letter and either a number or special character such as (!@#$%^&*-_=+[{}]\\|;:\'",<.>/?\')';
                    }
                }
                errors.passwordConfirm = this.state.passwordConfirm !== value ? 'X Passwords do not match.' : '';
                this.setState({touched: { ...this.state.touched, passwordTouched: true }});
                break;
            case 'passwordConfirm':
                errors.passwordConfirm = this.state.password !== value ? 'X Passwords do not match.' : '';
                this.setState({touched: { ...this.state.touched, passwordConfirmTouched: true }});
                break;
            default:
        };
        this.setState({ errors, [name]: value });
    };


    /**
     * @method
     * @param {FormEvent} event Event from form element.
     * @description Make an api request to proper end-point via the axios library.
     * @return void
     */
    handleSubmit = event => {
        event.preventDefault(); // prevent form submision
        const { password, token } = this.state;
        axios.post(`${process.env.REACT_APP_API}/auth-service/login/password`, {token, password})
        .then((response) => {
            if (response.status === 200) {
                window.parent.postMessage({passwordResetRedirect: true}, document.referrer.split('/').slice(0,3).join('/'));
            }
            else {
                const { message, status } = response.data;
                this.setState({exception: {error: true, errorMessage: message, code: status}});
            }
        })
        .catch(e => {
            this.setState({exception: {error: true, errorMessage: e.message, code: e.status}});
        });
    };


    /**
     * Is form valid
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.state;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(val => !val && (valid = false));
        return valid;
    };

    
    render() {

        const { touched, errors, exception } = this.state;

        const form = (
            <div className="login">
                <Alert active={exception.error} description={exception.errorMessage} code={exception.code} />
                <form onSubmit={ this.handleSubmit }>

                        <div className={`form-group ${errors.password && 'error'} ${touched.passwordTouched && !errors.password.length && 'success'}`}>
                            <label>Password<small>required</small></label>
                            <input type="password" className="form-control" name="password" onChange={e => this.handleChange(e)} />
                            <p className="text-error">{errors.password }</p>
                        </div>

                        <div className={`form-group ${errors.passwordConfirm && 'error'} ${touched.passwordConfirmTouched && !errors.passwordConfirm.length && 'success'}`}>
                            <label>Password Confirm<small>required</small></label>
                            <input type="password" className="form-control" name="passwordConfirm" onChange={e => this.handleChange(e)} />
                            <p className="text-error">{errors.passwordConfirm }</p>
                        </div>

                        <button type="submit" className={`btn primary ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Reset</button>

                </form>
            </div>
        );

        const view = this.state.fetching 
                        ? <Spinner color={"#999"} /> 
                        : !this.state.exception.load 
                            ? <Alert active={exception.error || !exception.load} description={exception.errorMessage} code={exception.code} /> 
                            : form;

        return (
            <main>
                { view }
            </main>
        )
    }
};

export default Recover;