import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import user from './user.redux';
import organization from './organization.redux';
import education from './education.redux';
import countries from './countries.redux';
import states from './states.redux';

export default (state = {}) => {

	// // populate middleware
	const middleware = [
		thunkMiddleware
	];

    // include all reducers
	const reducers = combineReducers({
		countries,
		states,
		userForm: user,
		organizationForm: organization,
		educationForm: education
    });
    
    // add logger to middleware
	if (process.env.NODE_ENV !== 'production') {
		if (typeof window !== 'undefined') {
			middleware.push(createLogger({collapsed: true}))
		}
	};

	// add enhancers to allow for reduxDevTools
	// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
	const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ /* Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize... */ })
        : compose;

	// add middleware to enhancers
	const enhancer = composeEnhancers(
		applyMiddleware(...middleware),
	);

    return createStore(reducers, state, enhancer);
}