import React, { Component } from 'react';
import axios from 'axios';
// import queryString from 'query-string';
import { Alert } from '../components';

/**
 * Login Component
 */

class Login extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            touched: {
                emailTouched: false,
                passwordTouched: false,
            },
            errors: {
                email: '',
                password: ''
            },
            exception: {
                error: false,
                errorDescription: '',
            },
            submitting: false
        };
    };


    /**
     * Is form valid
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.state;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(val => !val && (valid = false));
        return valid;
    };


    /**
     * Handle change
     * @method
     * @param {InputEvent} event Event from input field element.
     * @description On every key press of any of the input fields, confirm value is valid. If not valid, assign an error message to the errors state.
     */
    handleChange = event => {
        const { name, value } = event.target; // destruct event target
        let errors = this.state.errors;
        switch (name) {
            case 'email':
                const regex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
                errors.email = !regex.test(value) ? 'X Please use a valid email address, such as user@example.com.' : '';
                this.setState({touched: { ...this.state.touched, emailTouched: true }});
                break;
            case 'password':
                errors.password = value.length < 1 ? 'X You must enter a password.' : '';
                this.setState({touched: { ...this.state.touched, passwordTouched: true }});
                break;
            default:
                // console.error('form control not found.');
        };
        this.setState({ errors, [name]: value });
    };


    /**
     * Handle submit
     * @method
     * @param {FormEvent} event Event from form element.
     * @description On form submission, post form data via the axios library.
     */
    handleSubmit = event => {
        this.setState({error: false, submitting: true}); // reset alert
        event.preventDefault(); // prevent form submision
        const { email, password } = this.state;
        axios.post(`${process.env.REACT_APP_USER}/api/user/bc-login-redirect?redirect=false`, {
            brandCode: this.getBrandCode(),
            return_url: '/checkout.php',
            username: email,
            password
        }).then((response) => {
            window.top.location.href = response.data.redirectUrl;
        })
        .catch(e => {
            const { error_description } = e.response.data;
            this.setState({submitting: false, exception: {error: true, errorDescription: error_description}});
        });
    };


    /**
     * @method
     * @description Grab hostname and return the brandcode. If in a development environment, always return acls.
     * @return String
     */
    getBrandCode = () => {
        const url = (window.location !== window.parent.location) ? document.referrer : document.location.hostname;
        return url === 'localhost' ? 'acls' : url.split('.')[1];
    };


    render() {
        const { touched, errors, exception } = this.state;
        return (
            <main>
                <div className="login">
                    <Alert active={exception.error} description={exception.errorDescription} />
                    <form onSubmit={ this.handleSubmit }>
                        <div className="split-form">

                            <div className={`form-group ${errors.email && 'error'} ${touched.emailTouched && errors.email.length === 0 && 'success'}`}>
                                <label>Email Address:</label>
                                <input type="email" className="form-control" name="email" onChange={e => this.handleChange(e)}/>
                                <p className="text-error">{errors.email }</p>
                            </div>

                            <div className={`form-group ${errors.password && 'error'} ${touched.passwordTouched && errors.password.length === 0 && 'success'}`}>
                                <label>Password:</label>
                                <input type="password" className="form-control" name="password" autoComplete="false" onChange={e => this.handleChange(e)} />
                                <p className="text-error">{errors.password }</p>
                            </div>

                            <button type="submit" className={`btn primary ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid() || this.state.submitting}>Sign in</button>

                        </div>
                    </form>
                </div>
            </main>
        );
    };
};

export default Login;
