const initialUserFormState = {
    user: {
        guid: '',
        brandCode: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        country: 'United States of America',
        countryCode: 'US',
        state: '',
        stateCode: '',
        zip: '',
    },
    touched: {
        emailTouched: false,
        passwordTouched: false,  
        confirmPasswordTouched: false,
        firstnameTouched: false,
        lastnameTouched: false,
        address1Touched: false,
        cityTouched: false,
        countryTouched: true,
        stateTouched: false,
        zipTouched: false,
    },
    errors: {
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zip: ''
    },
    exception: {
        code: null,
        error: false,
        errorMessage: ''
    },
    validating: false
};

export default initialUserFormState;