import React from 'react';

/**
 * @method
 * @param {Array} options Array of select options.
 * @param {Function} options OnChange event function handler.
 * @param {Object} options Value of dataset value.
 */
const Select = ({options, tabIndex, change, value, name}) => {
    return <select className="form-control form-select" name={name} value={value} tabIndex={tabIndex} onChange={e => change(e)}>
        <option value="" defaultValue>{`Select ${name}`}</option>
        {
            options &&
            options.length &&
            options.map(option => <option key={option.countryCode || option.name || option.id} value={option.countryCode || option.code || option.value || option.id}>{option.name || option.organizationName}</option>)
        }
    </select>
};

export default Select;