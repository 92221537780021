import axios from 'axios';

const INITIALIZE_COUNTRIES = '@@register/INITIALIZE_COUNTRIES';

export const initializeCountriesStore = data => async (dispatch) => {
    const { data } = await axios.get(`${process.env.REACT_APP_USER}/api/countries`);
    dispatch({type: INITIALIZE_COUNTRIES, data})
};

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case INITIALIZE_COUNTRIES:
			return action.data
		default:
			return state;
	};
};