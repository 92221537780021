import React, { Component } from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux'
import axios from 'axios';
import { Select, Alert } from '../../components';
import { updateCountry, updateState } from '../../redux/helper.redux';
import { initializeCountriesStore } from '../../redux/countries.redux';
import { initializeStatesStore } from '../../redux/states.redux';
import { InputSpinner } from '../../components';

class UserForm extends Component {

    constructor() {
        super();
        this.state = {
            validating: false,
            submitting: false
        };
    };

    /**
	 * @method
	 * @description React lifecycle hook that will not perform logic until after the component fully mounts.
	 * @return {Void}
	 */
	componentDidMount = async () => {

        // get/set in redux countries
        this.props.initializeCountries();

        // get/set in redux states
        this.props.initializeStates(this.props.userForm.user.countryCode);

        // update brandcode in user store
        this.props.updateBrandCode(this.getQueryParam().brandCode);

        // if a cart id exists, update the redux store
        if (this.getQueryParam().cartId) this.props.updateCartId(this.getQueryParam().cartId);
    };


   /**
     * @method
     * @description Get query parameters.
     * @return {String}
     */
    getQueryParam = () => {        
        const {brandCode, cartId} = queryString.parse(window.location.search);
		return {brandCode, cartId};
    };


    /**
     * @method
     * @description Checks if group url query param exists and is set to true.
     * @return {Boolean}
     */
    isGroup = () => {
        const {group} = queryString.parse(window.location.search);
		return group === 'true';
    };


    /**
     * @method
     * @description Checks if any errors exist the errors state object.
     * @return {Boolean}
     */
    isRegisterFormValid = () => {
        const { errors, touched } = this.props.userForm;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(touched => !touched && (valid = false));
        return valid;
    };


    /**
     * @method
     * @description Checks if any errors exist the errors state object.
     * @return {Boolean}
     */
    isGroupFormValid = () => {
        const { errors } = this.props.userForm;
        const { firstnameTouched, lastnameTouched, passwordTouched, confirmPasswordTouched, emailTouched } = this.props.userForm.touched;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values({ firstnameTouched, lastnameTouched, passwordTouched, confirmPasswordTouched, emailTouched }).forEach(touched => !touched && (valid = false));
        return valid;
    };


    /**
     * @method
     * @description Check email address against server to ensure unique
     */
    checkemail = async (email) => {
        const regex = RegExp(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,10}$/);
        if ( regex.test(email) ) {
            this.setState({validating: true});
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API}/user-service/users/username`,  {username: email, brandCode: this.getQueryParam().brandCode });
                if (data) this.props.emailAlreadyExists();
            }
            catch(err) {
                const { message, status } = err.response.data;
                this.props.updateError({error: true, errorMessage: message, code: status})
            }
            this.setState({validating: false});
        }
    };


    /**
     * @method
     */
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.isRegisterFormValid()) {
            this.setState({submitting: true});
            axios.post(`${process.env.REACT_APP_API}/user-service/register`, {user: this.props.userForm.user, cartId: this.state.cartId}).then((response) => {
                axios.post(`${process.env.REACT_APP_API}/auth-service/oauth/token`, queryString.stringify({grant_type: 'password', username: this.props.userForm.user.email, password: this.props.userForm.user.password, brandCode: this.getQueryParam().brandCode}), {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': "Basic d2ViYXBwOndlYmFwcC1zZWNyZXQ="
					},
				}).then((response) => {
					const {access_token} = response.data;
					localStorage.setItem('auth', access_token);
				});
                window.top.location.href = response.data.redirectUrl;
            })
            .catch(e => {
                const { message, status } = e.response.data;
                this.props.updateError({error: true, errorMessage: message, code: status});
                this.setState({submitting: false});
            });
        }
        else {
            console.log('form is not valid', this.props.userForm.user);
        }
    };


    render() {
        const form = (
            <form onSubmit={e => this.handleSubmit(e)}>
                <Alert active={this.props.userForm.exception.error} description={this.props.userForm.exception.errorMessage} code={this.props.userForm.exception.code} />
                {
                    this.props.userForm.user &&
                    <div className="split-form">

                        <div className={`form-group ${this.props.userForm.errors.firstname && 'error'} ${this.props.userForm.touched.firstnameTouched && !this.props.userForm.errors.firstname.length && 'success'}`}>
                            <label>First Name <small>required</small></label>
                            <input type="text" className="form-control" name="firstname" tabIndex="1" value={this.props.userForm.user.firstname} onChange={e => this.props.onChangeFirstName(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.firstname }</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.lastname && 'error'} ${this.props.userForm.touched.lastnameTouched && !this.props.userForm.errors.lastname.length && 'success'}`}>
                            <label>Last Name <small>required</small></label>
                            <input type="text" className="form-control" name="lastname" tabIndex="2" value={this.props.userForm.user.lastname} onChange={e => this.props.onChangeLastName(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.lastname }</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.password && 'error'} ${this.props.userForm.touched.passwordTouched && !this.props.userForm.errors.password.length && 'success'}`}>
                            <label>Password <small>required</small></label>
                            <input type="password" className="form-control" name="password" tabIndex="3" value={this.props.userForm.user.password} onChange={e => this.props.onChangePassword(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.password }</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.confirmPassword && 'error'} ${this.props.userForm.touched.confirmPasswordTouched && !this.props.userForm.errors.confirmPassword.length && 'success'}`}>
                            <label>Confirm Password <small>required</small></label>
                            <input type="password" className="form-control" name="confirmPassword" tabIndex="4" value={this.props.userForm.user.confirmPassword} onChange={e => this.props.onChangeConfirmPassword(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.confirmPassword }</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.email && 'error'} ${this.props.userForm.touched.emailTouched && !this.props.userForm.errors.email.length && 'success'}`}>
                            <label>Email Address <small>required</small></label>
                            <input type="email" className="form-control" name="email" tabIndex="5" value={this.props.userForm.user.email} onChange={e => this.props.onChangeEmail(e.target.value)} onBlur={e => this.checkemail(e.target.value)} disabled={this.state.validating} />
                            { this.state.validating && <InputSpinner color={"#999"} /> }
                            <p className="text-error">{this.props.userForm.errors.email }</p>
                        </div>

                        <div className={`form-group`}>
                            <label>Phone Number</label>
                            <input type="text" className="form-control" name="phone" tabIndex="6" value={this.props.userForm.user.phone} onChange={e => this.props.onChangePhone(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.phone}</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.country && 'error'} ${this.props.userForm.touched.countryTouched && !this.props.userForm.errors.country.length && 'success'} ${this.isGroup() && 'hidden'}`}>
                            <label>Country <small>required</small></label>
                            <Select name="country" options={this.props.countries} tabIndex={7} value={this.props.userForm.user.countryCode} change={e => this.props.onChangeCountry(e)} />
                            <p className="text-error">{this.props.userForm.errors.country}</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.address1 && 'error'} ${this.props.userForm.touched.address1Touched && !this.props.userForm.errors.address1.length && 'success'} ${this.isGroup() && 'hidden'}`}>
                            <label>Address Line 1 <small>required</small></label>
                            <input type="text" className="form-control" name="address1" tabIndex="8" value={this.props.userForm.user.address1} onChange={e => this.props.onChangeAddress1(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.address1 }</p>
                        </div>

                        <div className={`form-group ${this.isGroup() && 'hidden'}`}>
                            <label>Address Line 2</label>
                            <input type="text" className="form-control" name="address2" tabIndex="9" value={this.props.userForm.user.address2} onChange={e => this.props.onChangeAddress2(e.target.value)} />
                        </div>                        

                        <div className={`form-group ${this.props.userForm.errors.city && 'error'} ${this.props.userForm.touched.cityTouched && !this.props.userForm.errors.city.length && 'success'} ${this.isGroup() && 'hidden'}`}>
                            <label>Suburb/City <small>required</small></label>
                            <input type="text" className="form-control" name="city" tabIndex="10" value={this.props.userForm.user.city} onChange={e => this.props.onChangeCity(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.city }</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.state && 'error'} ${this.props.userForm.touched.stateTouched && !this.props.userForm.errors.state.length && 'success'} ${this.isGroup() && 'hidden'}`}>
                            <label>State Province <small>required</small></label>
                            {
                                this.props.states
                                ? <Select name="state" options={this.props.states} tabIndex={11} change={e => this.props.onChangeState(e.target.value)} />    
                                : <input type="text" className="form-control" name="state" tabIndex="11" value={this.props.userForm.user.state} onChange={e => this.props.onChangeState(e.target.value)} />
                            }
                            <p className="text-error">{this.props.userForm.errors.state}</p>
                        </div>

                        <div className={`form-group ${this.props.userForm.errors.zip && 'error'} ${this.props.userForm.touched.zipTouched && !this.props.userForm.errors.zip.length && 'success'} ${this.isGroup() && 'hidden'}`}>
                            <label>Zip/Postcode <small>required</small></label>
                            <input type="text" className="form-control" name="zip" tabIndex="12" value={this.props.userForm.user.zip} onChange={e => this.props.onChangeZip(e.target.value)} />
                            <p className="text-error">{this.props.userForm.errors.zip }</p>
                        </div>
                        {
                            this.isGroup()
                            ? <button type="button" className={`btn primary ${!this.isGroupFormValid() && 'disabled'}`} disabled={!this.isGroupFormValid('group')} onClick={() => this.props.history.push('/organization')}>Continue</button>
                            : this.getQueryParam().brandCode === 'careerstep'
                                ? <button type="button" className={`btn primary ${!this.isGroupFormValid() && 'disabled'}`} disabled={!this.isGroupFormValid()} onClick={() => this.props.history.push('/education')}>Continue</button>
                                : <button type="submit" className={`btn primary ${!this.isRegisterFormValid() && 'disabled'}`} disabled={!this.isRegisterFormValid() || this.state.submitting}>Create Account</button>
                        }
                    </div>
                }
            </form>
        );

        return (
            <div>
                {
                    this.props.userForm ? form : <p>no form</p>
                }
            </div>
        );
    };
};


const mapStateToProps = state => ({
    userForm: state.userForm,
    education: state.educationForm,
    organization: state.organizationForm,
    countries: state.countries,
    states: state.states,
    cart: state.cart
});


const mapDispatchToProps = dispatch => {
	return {
        updateError: value => dispatch({ type: 'UPDATE_USER_ERROR', value }),
        updateBrandCode: value => dispatch({ type: 'UPDATE_USER_BRANDCODE', value }),
        updateCartId: value => dispatch({type: 'UPDATE_USER_CART_ID', value}),
        initializeCountries: () => dispatch(initializeCountriesStore()),
        initializeStates: countryCode => dispatch(initializeStatesStore(countryCode)),
        onChangeEmail: value => dispatch({ type: 'UPDATE_FIELD_USER_EMAIL', value }),
        emailAlreadyExists: () => dispatch({type: 'SET_EMAIL_ALREADY_EXISTS_EMAIL'}),
        onChangeConfirmPassword: value => dispatch({ type: 'UPDATE_FIELD_USER_CONFIRM_PASSWORD', value }),
        onChangeLastName: value => dispatch({ type: 'UPDATE_FIELD_USER_LAST_NAME', value }),
        onChangeAddress1: value => dispatch({ type: 'UPDATE_FIELD_USER_ADDRESS_1', value }),
        onChangeCity: value => dispatch({ type: 'UPDATE_FIELD_USER_CITY', value }),
        onChangeState: value => dispatch(updateState('UPDATE_FIELD_USER_STATE', value)),
        onChangePassword: value => dispatch({ type: 'UPDATE_FIELD_USER_PASSWORD', value }),
        onChangeFirstName: value => dispatch({ type: 'UPDATE_FIELD_USER_FIRST_NAME', value }),
        onChangePhone: value => dispatch({ type: 'UPDATE_FIELD_USER_PHONE', value }),
        onChangeAddress2: value => dispatch({ type: 'UPDATE_FIELD_USER_ADDRESS_2', value }),
        onChangeCountry: event => dispatch(updateCountry('UPDATE_FIELD_USER_COUNTRY', event.target.value)),
        onChangeZip: value => dispatch({ type: 'UPDATE_FIELD_USER_ZIP', value })
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
