import initialEducationFormState from './education.state';
import moment from 'moment';

export const updateCountry = value => async dispatch => {
    dispatch({type: 'UPDATE_FIELD_EDUCATION_COUNTRY', value});
};

export default (state = initialEducationFormState, action) => {
	switch (action.type) {
        case 'UPDATE_EDUCATION_ERROR':
            state.exception.error = action.value.error;
            state.exception.errorMessage = action.value.errorMessage;
            state.exception.code = action.value.code;
            return {
                ...state
            }
		case 'UPDATE_FIELD_EDUCATION_BIRTHDATE':
			// state.errors.birthdate = !regex.test(action.value) ? 'X You must enter a valid birthdate.' : '';
			state.errors.birthdate = moment(action.value, 'M/DD/YYYY', true).isValid() ? '' : 'X You must enter a valid birthdate.';
			state.errors.name = action.value.length < 1 ? 'X The \'Birthdate\' field cannot be blank.' : '';
			state.touched.birthdateTouched = true;
			state.data.birthdate = action.value;
            return {
                ...state
			}
        case 'UPDATE_FIELD_EDUCATION_LEVEL':
			state.errors.name = action.value.length < 1 ? 'X The \'Education Level\' field cannot be blank.' : '';
			state.data.level = action.value;
            state.touched.levelTouched = true;
            return {
                ...state
            }
        case 'UPDATE_FIELD_EDUCATION_YEAR':
			state.errors.name = action.value.length < 1 ? 'X The \'Year Education was Completed\' field cannot be blank.' : '';
			state.data.year = action.value;
			state.touched.yearTouched = true;
            return {
                ...state,
            }
        case 'UPDATE_FIELD_EDUCATION_NAME':
			state.errors.city = action.value.length < 1 ? 'X The \'Institution Name\' field cannot be blank.' : '';
			state.data.name = action.value;
			state.touched.nameTouched = true;
            return {
                ...state,
            }
        case 'UPDATE_FIELD_EDUCATION_CITY':
				state.errors.city = action.value.length < 1 ? 'X The \'City\' field cannot be blank.' : '';
				state.data.city = action.value;
                state.touched.cityTouched = true;
            return {
                ...state,
            }
        case 'UPDATE_FIELD_EDUCATION_COUNTRY':
			state.errors.country = action.value.length < 1 ? 'X The \'Country\' field cannot be blank.' : '';
			state.data.country = action.value;
			state.touched.countryTouched = true;
            return {
                ...state,
            }
        case 'UPDATE_FIELD_EDUCATION_STATE':
			state.errors.state = action.value.length < 1 ? 'X The \'State\' field cannot be blank.' : '';
			state.data.state = action.value;
			state.touched.stateTouched = true;
            return {
                ...state,
            }
        default:
            return state;
    }
};
