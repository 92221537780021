import http from './http.service';

/**
 * @class
 * @description Global functions related to cart.
 */
const CartService = {
	createCart: async (brandCode) => {
		return http.post('cart', `/api/cart`, brandCode)
	},
    getCart: async (guid) => {
        return http.get('cart', `/api/cart/${guid}`);
    },
    setCart: async (cart) => {
        return http.post('marketing', `/api/cart`, cart);
    },
    setSalesTax: async (cart) => {
        return http.post('marketing', `/api/cart/salestax`, cart);
	},
	addProducts: async (cartGuid, products) => {
		return http.post('cart', `/api/cart/${cartGuid}/products`, products);
	},
    buildCart: (cart, marketing) => {
        return {
			brandCode: cart.brandCode,
			currentSourceId: cart.currentSourceId,
			discountCode: cart.discountCode,
			guid: cart.guid,
			landingPage: cart.landingPage,
			originalLeadOwner: cart.originalLeadOwner,
			originalSourceId: cart.originalSourceId,
			products: cart.products,
			referrerCode: cart.referrerCode,
			selectedPaymentPlan: marketing.selectedPaymentPlan,
			shippingAddressId: null,
			shippingMethod: null,
			submissionPage: null,
			timezoneOffset: 360,
		};
    }
};

export default CartService;