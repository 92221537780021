import initialUserFormState from './user.state';

export default (state = initialUserFormState, action) => {
	switch (action.type) {
        case 'UPDATE_USER_ERROR':
            state.exception.error = action.value.error;
            state.exception.errorMessage = action.value.errorMessage;
            state.exception.code = action.value.code;
            return {
                ...state
            }
        case 'UPDATE_USER_BRANDCODE':
            state.user.brandCode = action.value;
            return {
                ...state
            }
        case 'UPDATE_USER_CART_ID':
            state.user.cartId = action.value;
            return {
                ...state
            }
		case 'UPDATE_FIELD_USER_EMAIL':
            const emailRegex = RegExp(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,10}$/);
            state.errors.email = !emailRegex.test(action.value) ? 'X You must enter a valid email.' : '';
            state.user.email = action.value;
            state.touched.emailTouched = true;
            return {
                ...state
            }   
        case 'SET_EMAIL_ALREADY_EXISTS_EMAIL':
            state.errors.email = 'X This email is already in use, please use a different one.';
            return {
                ...state
            }
        case 'UPDATE_FIELD_USER_PASSWORD':
            const capitalLetter = RegExp(/[A-Z]/);
            const lowercaseLetter = RegExp(/[a-z]/);
            const specialChar = RegExp(/[\d!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?']+/);
            if (action.value.length < 1) {
                state.errors.password = 'X You must enter a password.';                
            }
            else {
                if ( action.value.length >= 8 && capitalLetter.test(action.value) && lowercaseLetter.test(action.value) && specialChar.test(action.value) ) {
                    state.errors.password = '';                    
                }
                else {
                    state.errors.password = 'X Password must be at least 8 characters long and include an uppercase letter, a lowercase letter and either a number or special character such as (!@#$%^&*-_=+[{}]\\|;:\'",<.>/?\')';
                }
            }
            state.errors.confirmPassword = state.user.confirmPassword !== action.value ? 'X Passwords do not match.' : '';
            state.user.password = action.value;
            state.touched.passwordTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_CONFIRM_PASSWORD':
            state.errors.confirmPassword = state.user.password !== action.value ? 'X Passwords do not match.' : '';
            state.user.confirmPassword = action.value;
            state.touched.confirmPasswordTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_FIRST_NAME':
            state.errors.firstname = action.value.length < 1 ? 'X The \'First Name\' field cannot be blank.' : '';            
            state.user.firstname = action.value;
            state.touched.firstnameTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_LAST_NAME':
            state.errors.lastname = action.value.length < 1 ? 'X The \'Last Name\' field cannot be blank.' : '';
            state.user.lastname = action.value;
            state.touched.lastnameTouched = true;
            return {
                ...state
            }
        case 'UPDATE_FIELD_USER_PHONE':
            const phoneRegex = RegExp('1?[\\s\\-\\.]*\\(?([0-9]{3})\\)?[\\s\\-\\.]*([0-9]{3})[\\s\\-\\.]*([0-9]{4})');
            state.errors.phone = !phoneRegex.test(action.value) && action.value.length ? 'X You must enter a valid phone number.' : '';
            state.user.phone = action.value;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_ADDRESS_1':
            state.errors.address1 = action.value.length < 1 ? 'X The \'Address Line 1\' field cannot be blank.' : '';
            state.user.address1 = action.value;
            state.touched.address1Touched = true;
            return {
                ...state
            }         
        case 'UPDATE_FIELD_USER_ADDRESS_2':
            state.user.address2 = action.value;
            return {
                ...state
            }
        case 'UPDATE_FIELD_USER_CITY':
            state.errors.city = action.value.length < 1 ? 'X The \'Suburb/City\' field cannot be blank.' : '';
            state.user.city = action.value;
            state.touched.cityTouched = true;
            return {
                ...state
            }
        case 'UPDATE_FIELD_USER_COUNTRY':
            if (action.filter) {
                state.user.country = action.filter.name;                
                state.user.countryCode = action.filter.countryCode;
                state.user.state = '';
                state.user.stateCode = '';
                state.touched.stateTouched = action.states === null;;
                state.errors.country = '';
                state.errors.state = '';
            }
            else {
                state.user.countryCode = ''
                state.errors.country = 'X The \'Country\' field cannot be blank.';
            }
            state.touched.countryTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_STATE':
            const isObject = typeof action.selected === 'object';
            if (action.selected) {
                state.user.state     = isObject ? action.selected.name : action.selected;
                state.user.stateCode = isObject ? action.selected.code : null;
                state.errors.state = '';
            }
            else {
                state.user.state     = '';
                state.user.stateCode = null;
                state.errors.state = action.states !== null ? 'X The \'State/Province\' field cannot be blank.' : '';
            }
            if (action.states !== null) state.touched.stateTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_USER_ZIP':
            state.errors.zip = action.value.length < 4 ? 'X The \'Zip/Postcode\' must valid.' : '';
            state.user.zip = action.value;
            state.touched.zipTouched = true;
            return {
                ...state
            } 
        default:
            return state;
    }
};