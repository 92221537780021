import React, { Component } from 'react';
import queryString from 'query-string';
import { Alert, Spinner } from '../components';
import axios from 'axios';

/**
 * Assisted Cart Component
 */

class AssistedCart extends Component {

    constructor() {
        super();
        this.state = {
            guid: this.getGuid(),
            loadError: '',
            password: '',            
            passwordConfirm: '',            
            touched: {
                passwordTouched: false,
                passwordConfirmTouched: false,
            },
            errors: {
                password: '',
                passwordConfirm: ''
            },
            exception: {
                load: true,
                code: null,
                error: false,
                errorMessage: '',
            },
            requiresPassword: false,
            userId: null,
            cartUrl: null,
            username: null,
            fetching: true,
        }
    }


    /**
	 * @method
	 * @description React lifecycle hook that will not perform logic until after the component fully mounts. 
	 *              Get guid from state and, if present, start the api request sequence. If no guid state exists, stop the spinner so the error message is visable.
	 * @return {Void}
	 */
	componentDidMount = async () => {
        const { guid } = await this.state;
        this.cartValid(guid) ? this.loadData(guid) : this.setState({ fetching: false }) ;		
    };
    

    /**
	 * Cart is valid
	 * @method
	 * @param {String} guid Cart guid.
	 * @description Confirm there is a cart guid in state.
	 * @return {Boolean}
	 */
	cartValid(guid) {
		if (!guid) this.setState({ loadError: 'No cart specified '});
		return this.state.loadError < 1;
	};


    /**
	 * Get cart guid
	 * @method
	 * @description Grab the cart guid url query parameter using the `queryString` library.
	 * @return {String}
	 */
	getGuid = () => {
		const {c} = queryString.parse(window.location.search);
		return c;
    };
    

    /**
	 * Process api data
	 * @method
	 * @description Make api request to get the Big Commerce redirect url.
	 * @return {Void}
	 */
    loadData = guid => {
		const authJwt = localStorage.getItem('auth')
		axios.get(`${process.env.REACT_APP_API}/order-service/cart/${guid}/redirect/${authJwt}`)
		.then((response) => {
            console.log('Success', response.data);
            this.setState({requiresPassword: response.data.passwordRequired, userId: response.data.userId, username: response.data.username})
            if(!response.data.passwordRequired && response.data.urls) {
                window.location.replace(response.data.urls.cart_url); //  use window.replace to disable back arrow on Big Commerce
            }
            else if(response.data.urls) {
                this.setState({cartUrl: response.data.urls.cart_url})
            }
        })
        .catch(e => {
            let loadError = '';
            switch (e.response.status) {
                case 403:
                    loadError = 'cart link expired.';
                    break;
                default:
                    loadError = 'there was a problem with the request';
            }
            this.setState({fetching: false, loadError});
        });
    };

    /**
     * Handle change
     * @method
     * @param {InputEvent} event Event from input field element.
     * @description On every key press of any of the input fields, confirm value is valid. If not valid, assign an error message to the errors state.
     */
    handleChange = event => {        
        const { name, value } = event.target; // destruct event target
        let errors = this.state.errors;
        switch (name) {
            case 'password':
                const capitalLetter = RegExp(/[A-Z]/);
                const lowercaseLetter = RegExp(/[a-z]/);
                const specialChar = RegExp(/[\d!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?']+/);
                if (value.length < 1) {
                    errors.password = 'X You must enter a password.';                
                }
                else {
                    if ( value.length >= 8 && capitalLetter.test(value) && lowercaseLetter.test(value) && specialChar.test(value) ) {
                        errors.password = '';                    
                    }
                    else {
                        errors.password = 'X Password must be at least 8 characters long and include an uppercase letter, a lowercase letter and either a number or special character such as (!@#$%^&*-_=+[{}]\\|;:\'",<.>/?\')';
                    }
                }
                errors.passwordConfirm = this.state.passwordConfirm !== value ? 'X Passwords do not match.' : '';
                this.setState({touched: { ...this.state.touched, passwordTouched: true }});
                break;
            case 'passwordConfirm':
                errors.passwordConfirm = this.state.password !== value ? 'X Passwords do not match.' : '';
                this.setState({touched: { ...this.state.touched, passwordConfirmTouched: true }});
                break;
            default:
        };
        this.setState({ errors, [name]: value });
    };

    /**
     * @method
     * @param {FormEvent} event Event from form element.
     * @description Make an api request to proper end-point via the axios library.
     * @return void
     */
    handleSubmit = event => {
        event.preventDefault(); // prevent form submision
        const { password, userId, username } = this.state;
        axios.post(`${process.env.REACT_APP_API}/user-service/register/password`, {userId, password, username, redirect: this.state.cartUrl})
        .then((response) => {
            if (response.status === 200) {
                window.location.replace(response.data.redirectUrl); //  use window.replace to disable back arrow on Big Commerce
            }
            else {
                const { message, status } = response.data;
                this.setState({exception: {error: true, errorMessage: message, code: status}});
            }
        })
        .catch(e => {
            this.setState({exception: {error: true, errorMessage: e.message, code: e.status}});
        });
    };

    /**
     * Is form valid
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.state;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(val => !val && (valid = false));
        return valid;
    };


    render() {
        const { loadError, exception, touched, errors } = this.state;
        const view = this.state.fetching ? <Spinner color={"#999"} /> : this.state.loadError && <span>{loadError}</span>;

        const form = (
            <div className="login assisted-cart">
                <Alert active={exception.error} description={exception.errorMessage} code={exception.code} />
                <form onSubmit={ this.handleSubmit }>
                        <div className="explanation">
                            Please enter a password to complete your registration process.
                        </div>
                        <div className={`form-group ${errors.password && 'error'} ${touched.passwordTouched && !errors.password.length && 'success'}`}>
                            <label>Password<small>required</small></label>
                            <input type="password" className="form-control" name="password" onChange={e => this.handleChange(e)} />
                            <p className="text-error">{errors.password }</p>
                        </div>

                        <div className={`form-group ${errors.passwordConfirm && 'error'} ${touched.passwordConfirmTouched && !errors.passwordConfirm.length && 'success'}`}>
                            <label>Password Confirm<small>required</small></label>
                            <input type="password" className="form-control" name="passwordConfirm" onChange={e => this.handleChange(e)} />
                            <p className="text-error">{errors.passwordConfirm }</p>
                        </div>

                        <button type="submit" className={`btn primary ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Submit</button>

                </form>
            </div>
        );

        return (
            <main>
                { this.state.requiresPassword ? form : view }
            </main>
        )
    };
};

export default AssistedCart;