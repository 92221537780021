import http from './http.service';

/**
 * @class
 * @description Global functions related to marketing.
 */
const MarketingService = {
    getMarketing: async (brandCode, limit) => {
        return http.get('marketing', `/api/brand/${brandCode}?return=${limit}`);
    },
    setMarketing: async (cart, limit) => {
        return http.get('marketing', `/api/brand/${cart.brandCode}?return=${limit}`);
    },
    setMarketingOffers: async (guid, cart) => {
        return http.post('marketing', `/api/offers?brandCode=${cart.brandCode}&cartGuid=${guid}`, cart.products);
    }
};

export default MarketingService;