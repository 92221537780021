import http from './http.service';

/**
 * @class
 * @description Global functions related to opportunity.
 */
const OpportunityService = {
    getOpportunity: async (opportunity) => {
        return http.get('crm', `/api/opportunity/${opportunity}`);
    },
    emailAvailability: async (email) => {
        return http.get('user', `/api/user/email/available`, { email });
    },
    getAccount: async (account) => {
        return http.get('crm', `/api/account/${account}`);
    }
};

export default OpportunityService;