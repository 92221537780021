import React from 'react';

/**
 * @method
 * @param {Object} props Object containing active and description state.
 */
const Alert = props => (
    <div className={`alert${props.active ? ' active' : ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>
        <p>{ `${props.code ? `[${props.code}]` : ''} ${props.description}` }</p>
    </div>
);

export default Alert;