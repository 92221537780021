const initialOrganizationFormState = {
    user: {
        name: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        country: 'United States of America',
        countryCode: 'US',
        state: '',
        stateCode: '',
        zip: '',
    },
    touched: {
        nameTouched: false,
        countryTouched: true,
        address1Touched: false,
        cityTouched: false,
        stateTouched: false,
        zipTouched: false
    },
    errors: {
        name: '',
        phone: '',
        country: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
    },
    exception: {
        code: null,
        error: false,
        errorMessage: ''
    }  
};

export default initialOrganizationFormState;