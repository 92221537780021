import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Login, Recover, PasswordReset, Cart, AssistedCart, UserForm, OrganizationForm, EducationForm, RandomCart, OrganizationPickerForm } from './routes';
import './App.scss';

const App = () => (
	<Router>
		<Route path="/assisted-cart" component={ AssistedCart } />
		<Route path="/cart" component={ Cart } />
		<Route path="/signin" component={ Cart } />
		<Route path="/login" component={ Login } />
		<Route path="/register" exact component={ UserForm } />
		<Route path="/organization" exact component={ OrganizationForm } />
		<Route path="/organization-picker" exact component={ OrganizationPickerForm } />
		<Route path="/education" exact component={ EducationForm } />
		<Route path="/recover" exact component={ Recover } />
		<Route path="/password-reset" exact component={ PasswordReset } />
		<Route path="/random-cart" exact component={ RandomCart } />
	</Router>
)

export default App;
