import axios from 'axios';


/**
 * @class
 * @description Global Axios functions that allows for an easy implementation of evironment specific api urls.
 */

const http = {
    get: async (type, url, headers) => {
        const { data } = await axios.get(buildPath(type, url), headers);
        return data;
    },
    post: async (type, url, payload, headers) => {
        const { data } = await axios.post(buildPath(type, url), payload, headers);
        return data;
    }
};

const buildPath = (type, route) => {
    switch (type) {
        case 'cart':
            return `${process.env.REACT_APP_CART}${route}`;
        case 'marketing':
            return `${process.env.REACT_APP_MARKETING}${route}`;
        case 'crm':
            return `${process.env.REACT_APP_CRM}/tmp${route}`;
        case 'user':
            return `${process.env.REACT_APP_USER}${route}`;
        default:
            // console.log('no api path found);
    }
};

export default http;