import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { Spinner } from '../components';
import { CartService, MarketingService } from '../services';

/**
 * Cart Component
 */

class Cart extends Component {

	constructor(props) {
		super(props);
		this.state = {
			guid: this.getGuid(),
			cart: {},
			marketing: {},
			loadError: '',
            fetching: true,
		}
	};


	/**
	 * @method
	 * @description React lifecycle hook that will not perform logic until after the component fully mounts. 
	 *              Get guid from state and, if present, start the api request sequence. If no guid state exists, stop the spinner so the error message is visable.
	 * @return {Void}
	 */
	componentDidMount = async () => {
        const { guid } = await this.state;
		this.cartValid(guid) ? this.loadData() : this.setState({ fetching: false });
	};


	/**
	 * Get cart guid
	 * @method
	 * @description Grab the cart guid url query parameter using the `queryString` library.
	 * @return {String}
	 */
	getGuid = () => {
		const {c} = queryString.parse(window.location.search);
		return c;
	};


	/**
	 * Cart is valid
	 * @method
	 * @param {String} guid Cart guid.
	 * @description Confirm there is a cart guid in state.
	 * @return {Boolean}
	 */
	cartValid(guid) {
		if (!guid) this.setState({ loadError: 'No cart specified '});
		return this.state.loadError < 1;
	};


	/**
	 * Process api data
	 * @method
	 * @description Make all the api requests in sequence and build a cart object for the final `POST` request.
	 * @return {Void}
	 * @todo Do we need to implement pingdom and lucky orange?
	 */
	loadData = async () => {

		// get cart data and assign to state
		this.setState({cart: await CartService.getCart(this.state.guid)});

		// get marketing data and assign to state
		this.setState({marketing: await MarketingService.getMarketing(this.state.cart.brandCode, 'all')});

		// set cart data
		await CartService.setCart(CartService.buildCart(this.state.cart, this.state.marketing));

		// set marketing offerins
		await MarketingService.setMarketingOffers(this.state.guid, this.state.cart);

		// final cart request - not sure if this is actually needed.
		this.setState({cart: await CartService.setCart(CartService.buildCart(this.state.cart, this.state.marketing))});

		// turn off spinner
		this.setState({ fetching: false });

		// add pricebookSalesforceId to cart object
		this.setState({ cart: { ...this.state.cart, pricebookSalesforceId: this.state.marketing.pricebookSalesforceId, authJwt: localStorage.getItem('auth')} });

		// final call to get big commerce redirect url
		axios.post(`${process.env.REACT_APP_API}/order-service/cart?companyDivision=${this.state.marketing.companyDivision}`, this.state.cart)
		.then((response) => {
			window.location.replace(response.data.cart_url); //  use window.replace to disable back arrow on Big Commerce
        })
        .catch(e => {
            console.log('e', e);
        });
	}

    render() {
		const { loadError } = this.state;
		const view = this.state.fetching ? <Spinner color={"#999"} /> : this.state.loadError ? <span>{loadError}</span> : '';
        return (
            <main>
                { view }
            </main>
        )
    }
};

export default Cart;
