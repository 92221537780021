import React, { Component } from 'react';
import axios from 'axios';
import { Alert } from '../components';

/**
 * Recover Component
 */

class Recover extends Component {

    constructor() {
        super();
        this.state = {
            email: '',            
            response: '',
            touched: {
                emailTouched: false,
            },
            errors: {
                email: ''
            },
            exception: {
                code: null,
                error: false,
                errorMessage: '',
            }
        };
    };


     /**
     * @method
     * @param {InputEvent} event Event from form input element.
     * @description On every key press of any of the input fields, confirm value is valid. If not valid, assign an error message to the errors state.
     * @return void
     */
    handleChange = event => {
        const { name, value } = event.target; // destruct event target
        let errors = this.state.errors;
        const regex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        errors.email = !regex.test(value) ? 'X Please use a valid email address, such as user@example.com.' : '';
        this.setState({ errors, [name]: value, touched: {...this.state.touched, emailTouched: true }});
	};
	
	/**
     * @method
     * @description Grab hostname and return the brandcode. If in a development environment, always return acls.
     * @return String
     */
    getBrandCode = () => {
        const url = (window.location !== window.parent.location) ? document.referrer : document.location.hostname;
        return url === 'localhost' ? 'acls' : url.split('.')[1];
    };


    /**
     * @method
     * @param {FormEvent} event Event from form element.
     * @description Make an api request to proper end-point via the axios library.
     * @return void
     */
    handleSubmit = event => {
        event.preventDefault(); // prevent form submision
        const { email } = this.state;
        axios.post(`${process.env.REACT_APP_API}/auth-service/login/resetPassword`, {username: email, brand: this.getBrandCode()})
        .then((response) => {
            if (response.status === 200) {
                this.setState({response: 'Please check your email for the recovery link.'});
            }
            else {
                const { message, status } = response.data;
                this.setState({exception: {error: true, errorMessage: message, code: status}})
            }
        })
        .catch(e => {
            this.setState({exception: {error: true, errorMessage: e.message, code: e.status}});
        });
    };


    /**
     * Is form valid
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.state;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(val => !val && (valid = false));
        return valid;
    };
    

    render() {
        const { touched, errors } = this.state;

        const form = (
            <div className="login">
                <Alert active={this.state.exception.error} description={this.state.exception.errorMessage} code={this.state.exception.code} />
                <p>Fill in your email below to request a new password. An email will be sent to the address below containing a link to verify your email address.</p>
                <form onSubmit={ this.handleSubmit }>

                    <div className={`form-group ${errors.email && 'error'} ${touched.emailTouched && errors.email.length === 0 && 'success'}`}>
                        <label>Email Address:</label>
                        <div className="button-group">
                            <input type="email" className="form-control" name="email" onChange={e => this.handleChange(e)}/>
                            <button className={`btn primary ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Reset password</button>
                        </div>
                        <p className="text-error">{errors.email }</p>
                    </div>
                    
                </form>
            </div>
        );

        const success = (
            <div className="login">
                <p>{this.state.response}</p>
            </div>
        );

        return (
            <main>
                {!this.state.response ? form : success}
            </main>
        )
    }
};

export default Recover;
