import http from './http.service';

/**
 * @class
 * @description Global functions related to orders.
 */
const OrderService = {
    getOrderCart: async (userId) => {
        return http.get('cart', `/api/order/${userId}/cart`);
    },
    postProducts: async (products) => {
        return http.post('marketing', `/api/careerstep/products`, { products });
    }
};

export default OrderService;