import React, { Component } from 'react'; 
import axios from 'axios';
import queryString from 'query-string';
import {connect} from 'react-redux'
import { updateCountry, updateState } from '../../redux/helper.redux';
import { Select, Alert } from '../../components';
import { initializeCountriesStore } from '../../redux/countries.redux';
import { initializeStatesStore } from '../../redux/states.redux';
import {  } from '../../redux/organization.redux';

class OrganizationPickerForm extends Component {

    constructor() {
        super();
        this.state = {
            userId: this.getQueryParams().userId,
			cartId: this.getQueryParams().cartId,
            brandCode: this.getQueryParams().brandCode,
            orgId: this.getQueryParams().orgId,
            organizations: [],
            selectedOrganization: ''
        }
    };


    /**
     * Get associated organizations for the user
     */
    componentDidMount() {

        // get/set in redux countries
        this.props.initializeCountries();

        // get/set in redux states
        this.props.initializeStates();

        // get organizations
        this.getOrganizations();        
    }

    
    /**
     * @method
     * @description Get user id, cart id, and brand code from the url query parameter.
     * @return {Object}
     */
    getQueryParams = () => {
        const {userId, cartId, brandCode, orgId} = queryString.parse(window.location.search);
		return {userId, cartId, brandCode, orgId};
    };


    /**
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.props.organizationForm;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(touched => !touched && (valid = false));
        return valid;
    };


    /**
     * @method
     * @description Get a list of organizations from the user service to populate the select field
     * @return {Void}
     */
    getOrganizations = () => {        
		const token = localStorage.getItem('auth');
        axios.get(`${process.env.REACT_APP_API}/user-service/organizations?bigCommerceUserId=${this.state.userId}&brandCode=${this.state.brandCode}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
			this.setState({organizations: response.data });
        })
        .catch(e => {
           const { error_description } = e.response.data;
           this.props.updateError({error: true, errorMessage: error_description})
        });
    };


    /**
	 * 
     * @method
     * @description Submit selected organization to api
     * @return {Void}
     */
    handlePickerSubmit = e => {        
        e.preventDefault();
		const token = localStorage.getItem('auth');
		const org = this.state.organizations.find(element => element.id === parseInt(this.state.selectedOrganization));
        axios.post(`${process.env.REACT_APP_API}/user-service/organizations/user`, {
			bigCommerceUserId: this.state.userId, 
			cartId: this.state.cartId, 
			brandCode: this.state.brandCode,
			organization: {
				id: this.state.selectedOrganization
			}
		}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            console.log('posting', {orgName: org.organizationName, orgId: this.state.selectedOrganization});
            window.parent.postMessage({orgName: org.organizationName, orgId: this.state.selectedOrganization}, document.referrer.split('/').slice(0,3).join('/'));
        })
        .catch(e => {
           const { error_description } = e.response.data;
           this.props.updateError({error: true, errorMessage: error_description});
        });
    }


    /**
     * @method
     * @description Submit newly created organization to api
     */
    handleFormSubmit = e => {
        e.preventDefault();
		const token = localStorage.getItem('auth');
		axios.post(`${process.env.REACT_APP_API}/user-service/organizations/user`, {
			bigCommerceUserId: this.state.userId, 
			cartId: this.state.cartId, 
			brandCode: this.state.brandCode,
			organization: this.props.organizationForm.user
		}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
		.then(response => {

            console.log('response', response);

            // need to get returned object from the end-point and push it to the org array

            response.data.organizationName = response.data.name;

            this.setState({organizations: [...this.state.organizations, {id: response.data.id, organizationName: response.data.name}]}); // { id: response.data.id, organizationName: response.data.name }

            // need to pass the org id to will via postMessage
        	window.parent.postMessage({orgName: response.data.name, orgId: response.data.id}, document.referrer.split('/').slice(0,3).join('/'));

            // then need to clear the organization form - possibly call initialize organization form
            this.props.resetForm();            
        })
        .catch(e => {
            const { message, status } = e.response.data;
            this.props.updateError({error: true, errorMessage: message, code: status});
        });
    };


    resetForm = () => {

    };


    render() {
        return (
            <>

                <form onSubmit={e => this.handlePickerSubmit(e)}>
                    <div className='form-group'>
                        <label>Choose Organization:</label>
                        <div className="button-group picker-group">
                            <Select name="organization" options={this.state.organizations} value={this.state.selectedOrganization || this.state.orgId || ''} tabIndex={1} change={e => this.setState({selectedOrganization: e.target.value})} />
                            <button className={`btn primary ${!this.state.selectedOrganization && 'disabled'}`} tabIndex="2" disabled={!this.state.selectedOrganization}>Submit</button>
                        </div>
                    </div>
                </form>

                <h4 className="text-center">- OR -</h4>

                <form onSubmit={e => this.handleFormSubmit(e)}>

                    <Alert active={this.props.organizationForm.exception.error} description={this.props.organizationForm.exception.errorMessage} code={this.props.organizationForm.exception.code} />

                        <div className="split-form">

                            <div className={`form-group ${this.props.organizationForm.errors.name && 'error'} ${this.props.organizationForm.touched.nameTouched && !this.props.organizationForm.errors.name.length && 'success'}`}>
                                <label>Organization Name <small>required</small></label>
                                <input type="text" className="form-control" name="name" tabIndex="1" value={this.props.organizationForm.user.name} onChange={e => this.props.onChangeName(e.target.value)} />
                                <p className="text-error">{this.props.organizationForm.errors.name }</p>
                            </div>

                            <div className={`form-group ${this.props.organizationForm.errors.address1 && 'error'} ${this.props.organizationForm.touched.address1Touched && !this.props.organizationForm.errors.address1.length && 'success'}`}>
                                <label>Address Line 1 <small>required</small></label>
                                <input type="text" className="form-control" name="address1" tabIndex="2" value={this.props.organizationForm.user.address1} onChange={e => this.props.onChangeAddress1(e.target.value)} />
                                <p className="text-error">{this.props.organizationForm.errors.address1 }</p>
                            </div>  

                            <div className={`form-group ${this.props.organizationForm.errors.city && 'error'} ${this.props.organizationForm.touched.cityTouched && !this.props.organizationForm.errors.city.length && 'success'}`}>
                                <label>Suburb/City <small>required</small></label>
                                <input type="text" className="form-control" name="city" tabIndex="3" value={this.props.organizationForm.user.city} onChange={e => this.props.onChangeCity(e.target.value)} />
                                <p className="text-error">{this.props.organizationForm.errors.city }</p>
                            </div>

                            <div className={`form-group ${this.props.organizationForm.errors.state && 'error'} ${this.props.organizationForm.touched.stateTouched && !this.props.organizationForm.errors.state.length && 'success'}`}>
                                <label>State Province <small>required</small></label>
                                {
                                    this.props.states
                                    ? <Select name="state" options={this.props.states} tabIndex={4} value={this.props.organizationForm.user.stateCode} change={e => this.props.onChangeState(e.target.value)} />    
                                    : <input type="text" className="form-control" name="state" tabIndex="4" value={this.props.organizationForm.user.state} onChange={e => this.props.onChangeState(e.target.value)} />
                                }
                                <p className="text-error">{this.props.organizationForm.errors.state }</p>
                            </div>

                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" name="phone" tabIndex="5" value={this.props.organizationForm.user.phone} onChange={e => this.props.onChangePhone(e.target.value)} />
                                <p className="text-error">{this.props.organizationForm.errors.phone}</p>
                            </div>  

                            <div className="form-group">
                                <label>Address Line 2</label>
                                <input type="text" className="form-control" name="address2" tabIndex="6" value={this.props.organizationForm.user.address2} onChange={e => this.props.onChangeAddress2(e.target.value)} />
                            </div>

                            <div className={`form-group ${this.props.organizationForm.errors.country && 'error'} ${this.props.organizationForm.touched.countryTouched && !this.props.organizationForm.errors.country.length && 'success'}`}>
                                <label>Country <small>required</small></label>
                                <Select name="country" options={this.props.countries} value={this.props.organizationForm.user.countryCode} tabIndex={7} change={e => this.props.onChangeCountry(e)} />
                                <p className="text-error">{this.props.organizationForm.errors.country }</p>
                            </div>  

                            <div className={`form-group ${this.props.organizationForm.errors.zip && 'error'} ${this.props.organizationForm.touched.zipTouched && !this.props.organizationForm.errors.zip.length && 'success'}`}>
                                <label>Zip/Postcode <small>required</small></label>
                                <input type="text" className="form-control" name="zip" tabIndex="8" value={this.props.organizationForm.user.zip} onChange={e => this.props.onChangeZip(e.target.value)} />
                                <p className="text-error">{this.props.organizationForm.errors.zip }</p>
                            </div>  

                        </div>

                        <p>{this.props.continue}</p>
                        
                        <button type="submit" tabIndex="11" className={`btn primary ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Create Account</button>

                </form>
                
            </>
        );
    };
};


const mapStateToProps = state => ({
    userForm: state.userForm,
    organizationForm: state.organizationForm,
    countries: state.countries,
    states: state.states
});


const mapDispatchToProps = dispatch => {
	return {
        initializeCountries: () => dispatch(initializeCountriesStore()),
        initializeStates: () => dispatch(initializeStatesStore()),
        resetForm: () => dispatch({ type: 'RESET_ORGANIZATION_FORM' }),
        updateError: value => dispatch({ type: 'UPDATE_ORGANIZATION_ERROR', value }),
        onChangeName: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_NAME', value }),
        onChangePhone: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_PHONE', value }),
        onChangeAddress1: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ADDRESS_1', value }),
        onChangeAddress2: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ADDRESS_2', value }),
        onChangeCity: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_CITY', value }),
        onChangeCountry: event => dispatch(updateCountry('UPDATE_FIELD_ORGANIZATION_COUNTRY', event.target.value)),
        onChangeState: value => dispatch(updateState('UPDATE_FIELD_ORGANIZATION_STATE', value)),
        onChangeZip: value => dispatch({ type: 'UPDATE_FIELD_ORGANIZATION_ZIP', value })
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPickerForm);