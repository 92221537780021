import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore from '../src/redux/store';
import { Provider } from 'react-redux'; // redux bindings for react

import * as serviceWorker from './serviceWorker';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root')
);

serviceWorker.unregister();