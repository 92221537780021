import initialOrganizationFormState from './organization.state';

export const updateCountry = value => async (dispatch, getState) => {
    const countries = getState().countries;
    const filter = countries.filter(country => country.countryCode === value)[0];
    dispatch({type: 'UPDATE_FIELD_ORGANIZATION_COUNTRY', filter});
};

export const updateState = value => async (dispatch, getState) => {
    const states = getState().states;
    const filter = states.filter(state => state.code === value)[0];
    dispatch({type: 'UPDATE_FIELD_ORGANIZATION_STATE', filter});
};

export default (state = initialOrganizationFormState, action) => {
	switch (action.type) {
        case 'RESET_ORGANIZATION_FORM':
            state.user.address1 = '';
            state.user.address2 = '';
            state.user.city = '';
            state.user.country = 'United States of America';
            state.user.countryCode = 'US';
            state.user.name = '';
            state.user.phone = '';
            state.user.state = '';
            state.user.stateCode = '';
            state.user.zip = '';
            return {...state};
        case 'UPDATE_ORGANIZATION_ERROR':
            state.exception.error = action.value.error;
            state.exception.errorMessage = action.value.errorMessage;
            state.exception.code = action.value.code;
            return {
                ...state
            }
        case 'UPDATE_FIELD_ORGANIZATION_NAME':
            state.errors.name = action.value.length < 1 ? 'X The \'Organization Name\' field cannot be blank.' : '';
            state.user.name = action.value;
            state.touched.nameTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_ORGANIZATION_PHONE':
            const phoneRegex = RegExp('1?[\\s\\-\\.]*\\(?([0-9]{3})\\)?[\\s\\-\\.]*([0-9]{3})[\\s\\-\\.]*([0-9]{4})');
            state.errors.phone = !phoneRegex.test(action.value) && action.value.length ? 'X You must enter a valid phone number.' : '';
            state.user.phone = action.value;
            return {
                ...state
            }
        case 'UPDATE_FIELD_ORGANIZATION_ADDRESS_1':
            state.errors.address1 = action.value.length < 1 ? 'X The \'Address Line 1\' field cannot be blank.' : '';
            state.user.address1 = action.value;
            state.touched.address1Touched = true;
            return {
                ...state
            }         
        case 'UPDATE_FIELD_ORGANIZATION_ADDRESS_2':
            state.user.address2 = action.value;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_ORGANIZATION_CITY':
            state.errors.city = action.value.length < 1 ? 'X The \'Suburb/City\' field cannot be blank.' : '';
            state.user.city = action.value;
            state.touched.cityTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_ORGANIZATION_COUNTRY':
            if (action.filter) {
                state.user.country = action.filter.name;                
                state.user.countryCode = action.filter.countryCode;
                state.user.state = '';
                state.user.stateCode = '';
                state.touched.stateTouched = action.states === null;;
                state.errors.country = '';
                state.errors.state = '';
            }
            else {
                state.user.countryCode = ''
                state.errors.country = 'X The \'Country\' field cannot be blank.';
            }
            state.touched.countryTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_ORGANIZATION_STATE':
            const isObject = typeof action.selected === 'object';
            if (action.selected) {
                state.user.state     = isObject ? action.selected.name : action.selected;
                state.user.stateCode = isObject ? action.selected.code : null;
                state.errors.state = '';
            }
            else {
                state.user.state     = '';
                state.user.stateCode = null;
                state.errors.state = action.states !== null ? 'X The \'State/Province\' field cannot be blank.' : '';
            }
            if (action.states !== null) state.touched.stateTouched = true;
            return {
                ...state
            } 
        case 'UPDATE_FIELD_ORGANIZATION_ZIP':
            state.errors.zip = action.value.length < 4 ? 'X The \'Zip/Postcode\' must valid.' : '';
            state.user.zip = action.value;
            state.touched.zipTouched = true;
            return {
                ...state
            } 
        default:
            return state;
    }
};