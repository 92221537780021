import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import {connect} from 'react-redux'
import { updateCountry } from '../../redux/education.redux';
import { Select, Alert } from '../../components';

class EducationForm extends Component {

     /**
     * @method
     * @description Checks if any errors exist the errors state object.
     */
    isFormValid = () => {
        const { errors, touched } = this.props.educationForm;
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        Object.values(touched).forEach(val => !val && (valid = false));
        return valid;
    };

	/**
     * @method
     * @description Get query parameters.
     * @return {String}
     */
    getQueryParam = () => {        
        const {brandCode, cartId} = queryString.parse(window.location.search);
		return {brandCode, cartId};
	};
	
    /**
     * @method
     * @description Confirms if the education level is a GED or higher.
     * @param {String} level Education level.
     * @return Boolean
     */
    hasGED = (level) => {
        return (!level || level === 'none') ? false : true;
    };


    getYears = () => {
        let years = [];
        let thisYear = new Date().getFullYear();
        for (let y = thisYear; y >= thisYear - 75; y--) {
            years.push(y);
        };
        return years;
    };


    /**
     * @method
     * @param {Event} e Function event from form.
     * @description Checks to make sure user has at least a GED and posts full registration form. If no errors exists, will redirect user to the cart.
     * @return Void
     */
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.hasGED(this.props.educationForm.data.level)) {
            this.props.updateError({error: true, errorMessage: 'You must have completed high school or obtained a GED in order to sign up.', code: null})
        }
        else if (this.isFormValid()) {
            const payload = {
                user: {
                    ...this.props.userForm.user,
                    education: {
                        ...this.props.educationForm.data
                    }
                }
            };
            axios.post(`${process.env.REACT_APP_API}/user-service/register`, payload).then((response) => {
				axios.post(`${process.env.REACT_APP_API}/auth-service/oauth/token`, queryString.stringify({grant_type: 'password', username: this.props.userForm.user.email, password: this.props.userForm.user.password, brandCode: this.props.userForm.user.brandCode}), {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': "Basic d2ViYXBwOndlYmFwcC1zZWNyZXQ="
					},
				}).then((response) => {
					const {access_token} = response.data;
					localStorage.setItem('auth', access_token);
				});
                window.top.location.href = response.data.redirectUrl;
            })
            .catch(e => {
                const { message, status } = e.response.data;
                this.props.updateError({error: true, errorMessage: message, code: status})
            });
        }
        else {
            console.log('form is not valid', this.props.userForm.user);
        }
    };


    render() {
        return (
            <form onSubmit={e => this.handleSubmit(e)}>

                <Alert active={this.props.educationForm.exception.error} description={this.props.educationForm.exception.errorMessage} code={this.props.educationForm.exception.code} />

                <h2>Learner Information</h2>

                <div className={`form-group ${this.props.educationForm.errors.birthdate && 'error'} ${this.props.educationForm.touched.birthdateTouched && !this.props.educationForm.errors.birthdate.length && 'success'}`}>
                    <label>Birthdate <small>required</small></label>
                    <input type="text" className="form-control" name="birthdate" tabIndex="1" value={this.props.educationForm.data.birthdate} placeholder="mm/dd/yyyy" onChange={e => this.props.onChangeBirthdate(e.target.value)} />
                    <small>Please use the format mm/dd/yyyy. Certain programs have age requirements that we are compelled to verify.</small>
                    <p className="text-error">{this.props.educationForm.errors.birthdate }</p>
                </div>

                <h2>Prior Education</h2>

                <div className={`form-group ${this.props.educationForm.errors.level && 'error'} ${this.props.educationForm.touched.levelTouched && !this.props.educationForm.errors.level.length && 'success'}`}>
                    <label>Education Level <small>required</small></label>
                    <Select name="level" options={this.props.educationForm.levels} tabIndex={2} value={this.props.educationForm.data.level} change={e => this.props.onChangeLevel(e.target.value)} />
                    <p className="text-error">{this.props.educationForm.errors.level }</p>
                </div>

                <div className={`form-group ${this.props.educationForm.errors.year && 'error'} ${this.props.educationForm.touched.yearTouched && !this.props.educationForm.errors.year.length && 'success'}`}>
                    <label>Year Education was Completed <small>required</small></label>
                    <select className="form-control form-select" name="year" tabIndex="3" value={this.props.educationForm.data.year} onChange={e => this.props.onChangeYear(e.target.value)}>
                        <option value="">Select year</option>
                        {
                            this.getYears() &&
                            this.getYears().map(option => <option key={option}>{option}</option>)
                        }
                    </select>
                    <p className="text-error">{this.props.educationForm.errors.year }</p>
                </div>

                <div className={`form-group ${this.props.educationForm.errors.name && 'error'} ${this.props.educationForm.touched.nameTouched && !this.props.educationForm.errors.name.length && 'success'}`}>
                    <label>Institution Name <small>required</small></label>
                    <input type="text" className="form-control" name="name" tabIndex="4" value={this.props.educationForm.data.name} onChange={e => this.props.onChangeName(e.target.value)} />
                    <p className="text-error">{this.props.educationForm.errors.name }</p>
                </div>

                <div className={`form-group ${this.props.educationForm.errors.country && 'error'} ${this.props.educationForm.touched.countryTouched && !this.props.educationForm.errors.country.length && 'success'}`}>
                    <label>Country <small>required</small></label>
                    <Select name="country" options={this.props.countries} tabIndex="5" value={this.props.educationForm.data.country} change={e => this.props.onChangeCountry(e)} />
                    <p className="text-error">{this.props.educationForm.errors.country }</p>
                </div>

                <div className={`form-group ${this.props.educationForm.errors.city && 'error'} ${this.props.educationForm.touched.cityTouched && !this.props.educationForm.errors.city.length && 'success'}`}>
                    <label>City <small>required</small></label>
                    <input type="text" className="form-control" name="city" tabIndex="6" value={this.props.educationForm.data.city} onChange={e => this.props.onChangeCity(e.target.value)} />
                    <p className="text-error">{this.props.educationForm.errors.city }</p>
                </div>

                <div className={`form-group ${this.props.educationForm.errors.state && 'error'} ${this.props.educationForm.touched.stateTouched && !this.props.educationForm.errors.state.length && 'success'}`}>
                    <label>State Province <small>required</small></label>
                    {
                        this.props.states
                        ? <Select name="state" options={this.props.states} tabIndex={8} change={e => this.props.onChangeState(e.target.value)} />    
                        : <input type="text" className="form-control" name="state" tabIndex="8" value={this.props.educationForm.user.state} onChange={e => this.props.onChangeState(e.target.value)} />
                    }
                    <p className="text-error">{this.props.educationForm.errors.state }</p>
                </div>

                <button type="button" className="btn primary pull-left" onClick={() => this.props.history.push(`/register?brandCode=${this.props.userForm.user.brandCode}`)}>Back</button>

                <button type="submit" className={`btn primary pull-right ${!this.isFormValid() && 'disabled'}`} disabled={!this.isFormValid()}>Create Account</button>
                
            </form>
        );
    };
};


const mapStateToProps = state => ({
    userForm: state.userForm,
    educationForm: state.educationForm,
    countries: state.countries,
    states: state.states
});


const mapDispatchToProps = dispatch => {
	return {
        updateError: value => dispatch({ type: 'UPDATE_EDUCATION_ERROR', value }),
        onChangeBirthdate: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_BIRTHDATE', value }),
        onChangeLevel: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_LEVEL', value }),
        onChangeYear: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_YEAR', value }),
        onChangeName: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_NAME', value }),
        onChangeCountry: event => dispatch(updateCountry(event.target.value)),
        onChangeCity: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_CITY', value }),
        onChangeState: value => dispatch({ type: 'UPDATE_FIELD_EDUCATION_STATE', value }),
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);