import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import queryString from 'query-string';
import { Spinner } from '../components';
import { CartService, MarketingService } from '../services';

class RandomCart extends Component {
	constructor() {
		super();
		this.state = {
			brandCode: window.location.href.split('.')[1] || this.getBrandCode(),
			cart: {},
			marketing: {},
			loadError: '',
			fetching: true,
			product: {}
		}
	}

	componentDidMount = async () => {
		this.createRandomCart();
	};

	/**
	 * For local development since localhost does not have a brand code
	 */
	getBrandCode = () => {
		const {brandCode} = queryString.parse(window.location.search);
		return brandCode;
	};

	createRandomCart = async () => {
		this.setState({ cart: await CartService.createCart({brandCode: this.state.brandCode}) });

		// get marketing data and assign to state
		this.setState({ marketing: await MarketingService.getMarketing(this.state.brandCode, 'all') });

		let product = this.state.marketing.products[Math.floor(Math.random() * this.state.marketing.products.length)];
		product.qty = 1; // for now, let's just use one item so we don't break things
		this.setState({ product });

		this.setState({ cart: await CartService.addProducts(this.state.cart.guid, [this.state.product]) });

		// turn off spinner
        this.setState({ fetching: false });
	}

	handleRegenerateCart = async () => {
		this.setState({ fetching: true });

		this.createRandomCart();
	}

	goToBigCommerce = async () => {
		this.props.history.push(`/cart?c=${this.state.cart.guid}`)
	}

	render() {
		const { loadError } = this.state;
		const view = this.state.fetching ? <Spinner color={"#999"} /> :
			this.state.loadError ?
				<span>{loadError}</span> :
				<div>
					<ReactJson src={JSON.parse(JSON.stringify(this.state.cart))} theme="monokai" />
					<button type="button" className="btn primary" onClick={() => this.goToBigCommerce()}>Continue</button>
					<button type="button" className="btn primary" onClick={() => this.handleRegenerateCart()}>Regenerate Cart</button>
				</div>;
		return <main>
                { view }
            </main>
	}
}

export default RandomCart;
