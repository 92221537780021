import axios from 'axios';

/**
 * @method
 * 
 */
export const updateCountry = (action, value) => async (dispatch, getState) => {
    const countries = getState().countries;
    const filter = countries.filter(country => country.countryCode === value)[0];
    const {data} = await axios.get(`https://user-alpha.careerstep.com/api/${value}/states`);
    dispatch({type: 'UPDATE_STATES', data});
    dispatch({type: action, filter, states: data});
};

/**
 * @method
 * 
 */
export const updateState = (action, value) => async (dispatch, getState) => {
    const state = getState();
    const states = state.states;
    let selected = states ? states.filter(state => state.code === value)[0] : value;
    dispatch({type: action, selected, states: state.states});
};